/*---------------------------
 CSS Index  
****************************

01. Variables
02. Typography
03. Spacing
04. Reset
05. Forms
06. Shortcode
07. Common
08. Animations
09. Text Animation
10. Header
11. Nav
12. Mobilemenu
13. Rightdemo
14. Button
15. Accordion
16. Section Title
17. Banner
18. Service
19. Modal
20. Skill
21. Progressbar
22. Testimonial
23. Clients
24. Pricing
25. Contact
26. About
27. Brand
28. Footer
29. Portfolio
30. Slickstyle
31. Home Classic
32. White Version
----------------------------*/

/* Default  */

@import'default/variables';
@import'default/typography';
@import'default/spacing';
@import'default/reset';
@import'default/forms';
@import'default/mixins';
@import'default/shortcode';
@import'default/common';
@import'default/animations';
@import'default/text-animation';

/* Header  */
@import'header/header';
@import'header/nav';
@import'header/mobilemenu';
@import'header/rightdemo';
@import'header/dropdownmenu';



/* Elements  */
@import'elements/button';
@import'elements/accordion';
@import'elements/section-title';
@import'elements/banner';
@import'elements/service';
@import 'elements/modal';
@import'elements/skill';
@import'elements/progressbar';
@import'elements/testimonial';
@import'elements/clients';
@import'elements/pricing';
@import'elements/contact';
@import'elements/about';
@import'elements/brand';
@import'elements/footer';
@import'elements/portfolio';
@import'elements/slickstyle';
@import'elements/home-classic';
@import'elements/white-version';